@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500&family=Source+Sans+Pro:ital,wght@0,400;0,600;1,600&display=swap');

html,
body,
#root,
.app,
.content {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: 'IBM Plex Sans';

}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* handle */

::-webkit-scrollbar-thumb {
  background: #888;
}

/* handle on Hover */

::-webkit-scrollbar-track:hover {
  background: #555;
}

.drag-and-drop {
  width: 100%;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dragging {
  background-color: #f1f1f1;
}
